@import '~bootstrap/scss/bootstrap';

@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~icheck/skins/minimal/_all.css';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&family=Inter:wght@100;300;400;500;600;700;800;900&family=EB+Garamond:wght@400;500;600;700;800&display=swap');

$black: #080708;
$grey: #B2B6BD;
$light-grey: #E2E3E5;
$white: #FFFFFF;
$orange: #FFA000;
$amber: #FFBF00;

$mcc-dark-blue: #1B3545;
$mcc-light-blue: #4C6E93;
$yellow: #FFCD1B;

$error-red: #EB5757;
$error-red2: #FF7171;
$success-green: #27AE60;
$success-green2: #2BC26B;
$index-yellow: #F8CA28;
$index-yellow2: #F9D553;

@mixin font($color: $black, $font-family: 'Rubik', $font-weight: 400, $size: 16px, $line-height: 26px,  $text-transform: none ) {
  color: $color;
  font-size: $size;
  font-family: $font-family;
  line-height: $line-height;
  font-weight: $font-weight;
  text-transform: $text-transform;
}
.gold-yellow-gradient {
  background: linear-gradient(180deg, $orange -2.81%, #AA8622 15.69%, #C19920 44.48%, #E7B91D 80.47%, $yellow 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dark-light-blue-gradient {
  background: var(--MOI-grad-blues, linear-gradient(180deg, $mcc-dark-blue 0%, $mcc-light-blue 100%));
}
.orange-gradient {
  background: linear-gradient(180deg, $orange 0%, rgba(255, 160, 0, 0.00) 59.37%);
}
body {
  @include font();
  @include media-breakpoint-only(xs) {
    font-size: 14px;
  }
}
a {
  color: $orange;
}
.container-content a {
  color: $orange !important;
}
h1 {
  @include font($grey, 'Rubik', 700, 55px, normal);
  @include media-breakpoint-only(md){
    font-size: 35px;
  }
  @include media-breakpoint-only(sm){
    font-size: 30px;
  }
  @include media-breakpoint-only(xs){
    font-size: 25px;
  }
}
h2 {
  @include font($black, 'Rubik', 700, 35px, normal);
}
h3 {
  @include font($black, 'Rubik', 700, 30px, normal);
}
h4 {
  @include font($black, 'Rubik', 700, 25px, normal);
}
h5 {
  @include font($black, 'Rubik', 700, 20px, normal);
}
h6 {
  @include font($black, 'Rubik', 700, 15px, normal);
}
article h1 {
  color: $orange;
}
button {
  padding: 16px 48px;
  border-radius: 24px;
  @include font($mcc-dark-blue, Rubik, 400, 18px, normal, uppercase);
  border: 0;
}
button.yellow {
  background-color: $amber;
  color: $black;
}
button.yellow:hover {
  background-color: $orange;
  color: white;
}

button.yellow:active {
  background-color: $black;
  color: white;
}
button.yellow-border {
  background-color: transparent;
  border: 2px solid $amber;
  color: $yellow;
}
button.yellow-border:hover {
  background-color: transparent;
  border: 2px solid $orange;
  color: $orange;
}
button.yellow-border:active {
  background-color: transparent;
  border: 2px solid $black;
  color: $black;
}

button.dark {
  background-color: $mcc-dark-blue;
  color: white;
}
button.dark:hover {
  background-color: $mcc-light-blue;
  color: white;
}
button.dark:active {
  background-color: $orange;
  color: white;
}
button.dark-border {
  background-color: transparent;
  border: 2px solid $mcc-dark-blue;
  color: $mcc-dark-blue;
}
button.dark-border:hover {
  background-color: transparent;
  border: 2px solid $mcc-light-blue;
  color: $mcc-light-blue;
}
button.dark-border:active {
  background-color: transparent;
  border: 2px solid $orange;
  color: $orange;
}

button.white {
  background-color: $white;
  color: $mcc-dark-blue;
}
button.white:hover {
  background-color: $light-grey;
  color: $mcc-dark-blue;
}
button.white:active {
  background-color: $grey;
  color: white;
}
button.white-border {
  background-color: transparent;
  border: 2px solid $white;
  color: $white;
}
button.white-border:hover {
  background-color: transparent;
  border: 2px solid $light-grey;
  color: $light-grey;
}
button.white-border:active {
  background-color: transparent;
  border: 2px solid $grey;
  color: $grey;
}
a, a:hover {
  color: $black;
  text-decoration: none;
}
hr {
  color: $black !important;
  width: 139px;
}

.container, #main-navbar, .flash-container {
  max-width: 1320px;
  margin: 0 auto;
}

@include media-breakpoint-only(xl){
  .container, #main-navbar, {
    max-width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container, #main-navbar {
    max-width: 1320px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1600px) {
  .container, #main-navbar {
    max-width: 1520px;
    padding-left: 0;
    padding-right: 0;
  }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.50rem);
  padding-left: var(--bs-gutter-x, 0.50rem);
  margin-right: auto;
  margin-left: auto;
}

.container-content {
  max-width: 1180px;
}
/*body:not(.homepage) {
  .wide-container:before {
    background: url('/public/images/abszim-bg.png') top right no-repeat;
    opacity: 0.6;
  }
}
.wide-container:before {
  background: url('/public/images/abszim-bg.png') top right no-repeat;
  padding: 0;
  background-size: 100%;
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  @media (min-width: 1400px) {
    background-size: 1200px;
  }
  @media (min-width: 1600px) {
    background-size: 1400px;
    margin: 0 auto;
  }
}*/
body.homepage {
    .wide-container {
        @include media-breakpoint-up(md) {
          background: url('/public/images/abszim-bg.png') top right no-repeat;
          background-size: 80%;
        }
        @media (min-width: 1400px) {
          background-size: 1200px;
        }
        @media (min-width: 1600px) {
          background-size: 1400px;
          margin: 0 auto;
        }
        .hero {
          @media (min-width: 1400px) {
            min-height: 755px;
          }
          @media (min-width: 1600px) {
           min-height: 755px;
          }
          @media (min-width: 1400px) {
            display: flex;
            justify-content: right;
            align-items: stretch;
          }
        }

        .social-icons{
          @include media-breakpoint-up(sm) {
            padding: 20px 0;
          }
          @include media-breakpoint-up(xl) {
            @media (min-width: 1400px) { display: flex; }
            align-items: flex-end;
            div { line-height: 60px; }
          }
          a img {
            margin: 25px 15px;
          }
        }
    }
}
body:not(.homepage) {
  .wide-container {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      background: url('/public/images/abszim-bg.png') top right no-repeat;
      opacity: 0.1;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: -1;
      @media (min-width: 1400px) {
        background-size: 1200px;
      }
      @media (min-width: 1600px) {
        background-size: 1400px;
        margin: 0 auto;
      }
    }
  }
}

header {
  height: 150px;
  margin-bottom: 1.5rem;


  .container {
    padding-top: 15px;
  }
}

header.homepage {
  height: 587px;
  margin-bottom: 40px;

  .natural-bg-bottom-border {
    background-position: bottom;
    background-repeat: repeat-x;
    height: 587px;

    .natural-bg {
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: 1920px 587px;
      height: 587px;
    }
  }

  #main-navbar {
    max-width: 1200px;
    margin: 0 auto 0 auto;
    padding: 13px 49px;

    a {
      font-size: 15px;
    }

    .nav-link {
      padding: 0 24px;
    }

    #search-link {
      left: 957px;
    }

    #icon-facebook-link {
      left: 1057px;
    }

    #icon-youtube-link {
      left: 1100px;
    }
  }

  #logo-homepage-link {
    display: none;
  }
}
.amber {
  color: $amber;
}
#main-navbar {
  margin: 0 auto;
  position: relative;

  #logo-homepage-link {
    margin-right: 15px;
  }

  .nav-link {
    @include font($black, 'Rubik', 400, 18px, 28px);
    padding: 0 10px;
  }

  .nav-item {

  }

  .nav-item.current .nav-link {
    color: $orange;
  }

  .nav-item:last-child {
    border-right: none;
  }

  .nav-icon {
    background-repeat: no-repeat;
    width: 33px;
    height: 33px;
    position: absolute;
    top: 12px
  }
}

#search-link {

}


#icon-facebook-link {
  left: 1089px;
}

#icon-youtube-link {
  left: 1132px;
}

.item-img, .article-image {
  max-width: 100%;
  height: auto;
}

.article-body {
  img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
  figure {
    display: inline-block;
    margin: 0 1rem 1rem 1rem;
  }

  figure img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  figure > figcaption {
    padding: 0 19px;
    margin: 0;
    background: #cccccc;

    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 40px;

    color: #FFFFFF;
    display: block; /* For IE8 */
  }
}
.article-lead {
  font-weight: bold;
}
.article-image {
  margin-bottom: 1rem;
}

.article-title {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #00c0ef;
  margin-bottom: 1rem;
}

.article-public-date {
  background-position: 0 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #737373;
  margin-bottom: 1rem;

  .article-date, .article-time {
    background-color: #fff;
    padding: 0 10px;
  }

  .article-date {
    border-right: 1px solid #cccccc;
    padding-left: 0;
  }
}

.article-image-container {
  img {
    width: 100%;
  }
}

.event-image-container {
  position: relative;
  margin-bottom: 5rem;
  .event-date {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    position: absolute;
    min-width: 140px;

    left: 39px;
    bottom: -42px;

    hr {
      width: 20px;
      border: none;
      outline: none;
      border-bottom: 1px solid #fff;
      margin: 6px auto 10px auto;
      color: #fff;
    }

    .day {
      font-size: 36px;
      line-height: 36px;
    }

    .day.one {
      font-size: 60px;
      line-height: 52px;
    }

    .date {
      background: #cccccc;

      .date-content {
        padding: 20px 15px 20px 15px;
      }
    }

    .time {
      background: #00c0ef;
      line-height: 58px;
      font-weight: 700;
      font-size: 17px;
    }
  }
}

.btn-gold {
  padding: 13px 30px;

  background: #cccccc;
  border: none;
  border-radius: 10px;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFFFFF;
}

.btn-gold:hover {
  background: #00c0ef;
  border-color: #00c0ef;
  color: #fff;
}

.btn-gold-rounded, .btn-primary.btn-primary-rounded {
  padding: 22px 23px;

  border: none;
  border-radius: 30px;
  font-weight: 700;
  line-height: 18px;

  .fa {
    margin-left: 10px;
  }
}

.text-right {
  text-align: right;
}

.event-row {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 20px;

  .event-content {
    padding: 5px 0 0 50px;
    width: 100%;

    .event-address {
      font-size: 13px;
      line-height: 16px;
      color: #737373;
      margin-bottom: 10px;
    }

    .event-lead {
      font-size: 17px;
      line-height: 29px;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 22px;
      line-height: 27px;
    }
  }
}

.event-row:last-child {
  border-bottom: none;
}

.event-list-image-container {
  position: relative;
  max-width: 209px;

  img {
    max-width: 209px;
  }

  .item-img-cropped {
    border-radius: 10px;
    margin-bottom: 0;
  }

  .event-date {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    min-width: 74px;
    position: absolute;
    right: -32px;
    top: 15px;

    hr {
      width: 20px;
      border: none;
      outline: none;
      border-bottom: 1px solid #fff;
      margin: 6px auto 10px auto;
      color: #fff;
    }

    .month {
      font-size: 7px;
      line-height: 9px;
    }

    .day {
      font-size: 22px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    .date {
      background: #cccccc;
      border-radius: 10px;

      .date-content {
        padding: 12px 3px 10px 3px;
      }
    }

    .time {
      background: #00c0ef;
      border-radius: 10px;

      font-size: 9px;
      line-height: 26px;
    }
  }
}



#searchModal {

  .modal-content {
    background: none;
    border: none;
  }

  .modal-dialog {
    max-width: 770px;
  }

  input {
    border-radius: 70px;
    font-family: 'Rubik', sans-serif;
    color: #00c0ef;
    font-weight: 700;
    font-size: 15px;
    line-height: 55px;
    padding: 0 25px;
    width: 100%;
  }

  form {
    position: relative;

    button.submit {
      border: none;
      background-repeat: no-repeat;
      width: 70px;
      height: 70px;
      position: absolute;
      right: 78px;
      top: -7px
    }
  }

  button.close {
    border: none;
    background: none;
  }
}

.authors {
  margin-bottom: 1rem;

  img.img-round {
    width: 50px;
    height: auto;
    border-radius: 25px;
  }

  .connector {
    text-transform: capitalize;
  }
}

blockquote {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;

  color: #cccccc;

  border-style: solid;
  border-color: #cccccc;
  border-width: 0;

  margin-left: 6rem;
  padding-left: 4rem;
  padding-right: 8px;
  border-left-width: 4px;
}

.heading {
  background-position: center;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  h1 {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;

    display: inline;
    background-color: #fff;
    padding-right: 10px;
    padding-left: 1px;
    line-height: 53px;

    color: $orange;
  }
}

.heading-grey {

  h5 {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 55px;

    text-transform: uppercase;

    color: #ABABAB;

    display: inline;
    background-color: #fff;
    padding-right: 10px;
    padding-left: 1px;
  }
}
header {
  .offcanvas-body {
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }
  .navbar-nav {
    background-color: white;
    padding: 10px ;
  }
  .social-icon {
    @include font(white, Rubik, 400, 18px, 28px);
    padding: 10px 5px;
    li
    img {
      margin-right: 5px;
    }
    a {
      @include font(white, Rubik, 400, 18px, normal);
    }
  }
}
.facebook-line {
  text-align: right;
  margin-bottom: 15px;

  a {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    color: #B8B8B8;

    display: inline;
    background-color: #fff;
    padding-right: 2px;
    padding-left: 10px;

    img {
      margin-left: 5px;
    }
  }
}
.hide-email {
  cursor: pointer;
}
.item-list {
  .item.higlighted {

    .item-img, .item-img-cropped {
      border-bottom: 5px solid #cccccc;
    }
  }

  .item.higlighted.first {
    .item-img, .item-img-cropped {
      border-bottom: none;
    }
  }

  .col {
    margin-bottom: 40px;
  }

  .item {
    font-size: 15px;
    line-height: 26px;
    min-height: 100%;

    .item-img, .item-img-cropped {
      border-radius: 10px;
    }

    .item-body {

      h2 {
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;

        color: #00c0ef;

        margin-bottom: 20px;
      }
    }
  }

  .item.first {
    .item-img {
    }
  }
}

.item-img-cropped {
  border-radius: 10px;
  margin-bottom: 20px;
  object-fit: cover;
  max-width: 100%;
}

.location-list {
  .col {
    margin-bottom: 32px;
  }

  .item {
    background: #fff;
    border: 1px solid #B8B8B8;
    padding: 30px;
    height: 100%;

    h2 {
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;

      color: #00c0ef;
      margin-bottom: 20px;
    }

    .item-body {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 29px;
    }
  }

  .item.higlighted {
    border: 2px solid #cccccc;
  }
}

.event-list, .sidebox {
  .higlighted img {
    border: 2px solid #cccccc;
  }
}

.sidebox {
  font-family: 'Rubik', sans-serif;
  margin-bottom: 1.5rem;

  .box-header {
    background-position: center;
    margin-bottom: 10px;

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 53px;

      color: #cccccc;
      background: #ffffff;
      display: inline;
      padding-right: 15px;
    }
  }

  .box-body {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #737373;

    .item-body {
      margin-left: 20px;
    }

    .row {
      border-bottom: 1px solid #D9D9D9;
      margin: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;

      > * {
        padding: 0;
      }
    }

    .row:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 20px;
    }

    h2 {
      line-height: 18px;

      a {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;

        color: #000000;
      }
    }
  }

  .box-footer {
    .btn-primary {
      width: 100%;
    }
  }
}

.btn-primary {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;

  justify-content: center;
  align-items: center;
  line-height: 30px;

  background: #00c0ef;
  border-color: #00c0ef;
  border-radius: 10px;
}

.btn-primary:hover {
  background: #cccccc;
  border-color: #cccccc;
}

.tags {
  margin-bottom: 20px;

  a {
    background: #ABABAB;

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    padding: 5px;

    color: #FFFFFF;
    margin-right: 10px;
  }
}

.halozat {
  .content-border {
    padding-left: 110px;
  }
}



.welcome {
  margin-bottom: 70px;

  .welcome-flower {
  }

  .welcome-text {
    h1 {
      font-weight: 400;
      font-size: 32px;
      line-height: 53px;
      text-transform: uppercase;
      color: #cccccc;
    }

    hr {
      width: 50%;
    }

    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
  }

  .welcome-stock {
  }
  > .row {
    min-height: 295px;
  }
}

.home-locations {
  position: relative;
  padding: 90px 0 60px 0;

}

#home-locations-map {
  position: relative;
  height: 730px;

  .home-filter-bar-container {
    position: absolute;
    top: -35px;
    z-index: 1;
    width: 80%;
    margin: 0 10% 0 10%;
    display: flex;
    justify-content: center;

    .home-filter-bar {
      padding-top: 20px;
      margin: 0 auto;
    }
  }
}

.home-filter-bar {
  margin: -125px auto 50px auto;
  line-height: 70px;
  min-height: 70px;
  padding: 0 80px 0 30px;
  display: inline-block;

  .navbar-toggler {
    line-height: 70px;
  }

  a, form {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;

    border-right: 1px solid #454F7A;

    text-transform: uppercase;

    color: #FFFFFF;
    padding: 0 20px;
  }

  a.gold, a:hover {
    color: #cccccc;
  }

  form {
    display: inline;

    div {
      display: inline;
    }

    select {
      padding: 2px 27px 2px 3px;
      appearance: none;
      margin: 0;
      border: none;
      display: inline;

      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      max-width: 130px;
      line-height: 26px;

      color: #FFFFFF;

      option {
        background: #00c0ef;
        display: inline;
        border: none;
        outline: none;
      }

      option:first-child {
        text-transform: uppercase;
      }
    }
  }
}

.filter-bar {

  margin: 0 0 30px 0;
  line-height: 70px;
  padding: 0 80px 0 30px;
  display: inline-block;

  a {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;

    text-transform: uppercase;

    color: #FFFFFF;
    padding: 0 10px;
    border-right: 1px solid #454F7A;
  }
  a.gold, a.selected {
    color: #cccccc;
  }

  form {
    display: inline;

    div {
      display: inline;
    }

    select {
      padding: 2px 27px 2px 3px;
      appearance: none;
      margin: 0 10px;
      border: none;
      display: inline;

      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      max-width: 130px;

      color: #FFFFFF;

      option {
        background: #00c0ef;
        display: inline;
        border: none;
        outline: none;
      }

      option:first-child {
        text-transform: uppercase;
      }
    }
  }
}

.blue-spacer {
  border-right: 1px solid #454F7A;
}

.filter-container {

  display: flex;
  justify-content: center;
}


.gold {
  color: #cccccc;
}

hr {
  color: #00c0ef;
  opacity: initial;
}

.slick-prev::before, .slick-next::before {
  color: #D9D9D9;
}

.home-events {
  padding-top: 80px;
}

.home-header {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 40px auto;

  h2 {
    display: inline;
    font-weight: 300;
    font-size: 36px;
    line-height: 53px;
    text-transform: uppercase;
    color: #00c0ef;
    background: #ffffff;
    padding: 0 20px;
  }
}

.home-partners {
  background: #F4F4F4;
  height: 236px;
  line-height: 236px;

  .home-partners-list {
    display: inline;
  }

  .home-header {
    padding: 0;
    margin: 0;
    display: inline;

    h2 {
      display: inline;
      background: #F4F4F4;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 36px;

      text-transform: uppercase;

      color: #00c0ef;
      padding: 0;
    }
  }

  .container img {
    margin: 0 25px;
  }
}

footer {
  min-height: 224px;
  background-color: $white;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-top: 30px;
  h5 {
    @include font($black, Rubik, 400, 18px, normal, uppercase);
  }
  div.social-icons {
    display: block !important;
    padding-top: 0 !important;
    a img {
      margin: 0 10px 10px 10px !important;
    }
  }
}

.home-event-slider.slick-dotted.slick-slider {
  margin-bottom: 50px;
}

.home-event-slider {
  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button::before {
    font-size: 12px;
  }

  .slick-dots li.slick-active button::before {
    color: #cccccc;
  }

  .event-row {
    margin-bottom: 0;
  }

  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    top: 40%;
  }

  .slick-prev:before {
    content: "";
  }

  .slick-next:before {
    content: "";
  }

  .slick-prev {
    left: -80px;
  }

  .slick-next {
    right: -80px;
  }

  .item {
    padding: 0 15px;
  }
}


.sidebox.events {
  .date-content {
    width: 64px;
    height: 64px;
    padding: 12px 5px;

    background: #00c0ef;
    border-radius: 10px 0;

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 9px;
    text-transform: uppercase;
    text-align: center;

    color: #FFFFFF;

    .day {
      font-size: 16px;
      line-height: 14px;
    }

    .day.one {
      font-size: 32px;
      line-height: 14px;
    }

    hr {
      color: #FFFFFF;
      width: 20px;
      margin: 5px auto 10px auto;
    }
  }
}

.sidebox {
  .item-body a {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    display: block;
    margin-bottom: 10px;
  }

  .item-img, .item-img-cropped {
    width: 60px;
    border-radius: 0;
    margin-bottom: 0;
  }
}

.sidebox.locations {
  .item-img-cropped {
    border-radius: 10px;
  }
}

.sidebox-line {
  background-position: 0 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #737373;

  .part1, .part2 {
    background-color: #fff;
    padding: 0 10px;
  }

  .part1 {
    text-transform: uppercase;
    padding-left: 0;
  }

  .part2 {
    border-left: 1px solid #cccccc;
  }
}

.pagination {
  line-height: 60px;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  a {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  .page a {
    background: #ECECEC;

    text-align: center;

    display: inline-block;
    line-height: 42px;
    width: 42px;
    height: 42px;
    margin: 0 10px;

    color: #00c0ef;
  }

  .current {
    background: #cccccc;

    display: inline-block;
    line-height: 60px;
    text-align: center;

    width: 60px;
    height: 60px;
    margin: 0 10px;

    color: #ffffff;
  }

  .next a, .last a, .first a, .previous a {
    border-radius: 42px;
    line-height: 42px;
    text-align: center;

    width: 42px;
    height: 42px;
    margin: 0 10px;

    border: 1px solid #ECECEC;
    display: inline-block;

    color: transparent;

  }

  .last a {
    transform: rotate(180deg);
  }

  .next a {
    transform: rotate(180deg);
  }

  .previous a {
  }

  .first a {
  }
}

.event-info {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #737373;
  margin-bottom: 15px;

  .title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #00c0ef;
  }
}

.event-is-over {
  > .article-image-container > .event-date,
  > .event-list-image-container > .event-date,
  > .event-date {
    text-decoration: line-through;
    color: #cccccc;
  }
}

.event-prev-col {
  position: relative;

  > a {
    position: absolute;
    top: 50px;
    left: 31px;

    display: inline-block;
    line-height: 42px;
    text-align: center;

    width: 42px;
    height: 42px;
    margin: 0 10px;
    border-radius: 42px;

    color: transparent;
  }
}

button.navbar-toggler {
  border: none;
  box-shadow: none;
  color: #cccccc;
}

.map {
  overflow: hidden;
  resize: none;
  max-width: 100%;
  width: 100%;
  height: 500px;
  margin-bottom: 2rem;

  .map-generator {
    max-width: 100%;
    max-height: 100%;
    background: none;
  }

  .embed-ded-map-canvas {
    height: 100%;
    width: 100%;
    max-width: 100%;

    iframe {
      height: 100%;
      width: 100%;
      border: 0;
    }
  }
}

.search-result-list {
  .item-list > .row {
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .item-list .item .item-img-cropped {
    border-radius: 10px;
  }

  h2 {
    font-size: 22px;
  }
}

body {
  .ch-cookie-consent {
    position: fixed;
    padding: 10px 30px;
    bottom: 0;
    left: 0;
    border-top: 2px solid #cccccc;
    font-size: 14px;

    h3, .h3, h4, .h4, h5, .h5 {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .ch-cookie-consent__category {
    padding: 15px 0;
  }

  .ch-cookie-consent__category-group {
    overflow: auto;
  }

  .ch-cookie-consent__form {
    margin-top: 10px;
  }

  .ch-cookie-consent--simplified .ch-cookie-consent__btn-group {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .ch-cookie-consent__btn {
    background: #ccc;
  }

  .ch-cookie-consent__btn--secondary {
    background: #cccccc;
  }

  .ch-cookie-consent__btn--secondary:hover {
    background: #00c0ef;
    border-color: #00c0ef;
    color: #fff;
  }
}

@media (max-width: 1200px) {
  #home-locations-map {
    .home-filter-bar-container {
      width: 90%;
      margin: 0 5% 0 5%;
      top: -50px;
    }
  }
  .filter-bar {
    margin-bottom: 0;
  }
  #main-navbar {
    background: none;
    padding: 0;
    justify-content: space-between;

    .navbar-nav {
      margin-bottom: 20px;
    }

    .nav-item {
      border: none;
      border-bottom: #B8B8B8 1px solid;
    }

    .nav-item:last-child {
      border: none;
    }

    .nav-icon {
      position: initial;
      display: inline-block;
    }

    a.social-icon {
      background-color: #9A9A9A;
      margin-right: 10px;
    }

    #search-link {
      position: initial;
      display: inline-block;
      float: right;
    }

    .nav-link {
      line-height: 60px;
      font-size: 18px;
    }

    #main-navbar-content {
      background-color: rgba(178, 182, 189, 0.9);
      width: 100%;

      .offcanvas-header {
        justify-content: right;
      }

      .btn-close {
        background: #cccccc;
        mask-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
        filter: none;
        opacity: 1;
      }
    }

    #logo-homepage-link {
      top: 10px;
      box-shadow: none;
    }
  }
  .navbar-toggler {
    font-size: 27px;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  header {
    .container {
      padding-top: 10px;
    }
  }

  header.homepage, header {
    background-color: white;
    margin-bottom: 1.5rem;
    height: auto;

    #main-navbar {
      padding: 0;
    }

    .container {
      padding-top: 10px;
    }

    #main-navbar #logo-homepage-link {
      background: none;
      width: 80%;
      left: 0;
      top: 0;
    }

    .natural-bg-bottom-border {
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: auto 4px;

      .natural-bg {
        background-position: bottom left -117px;
        background-repeat: no-repeat;
        background-size: 660px auto;
        .container {
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  .home-event-slider .slick-next {
    right: -20px;
  }
  .home-event-slider .slick-prev {
    left: -20px;
  }
  .home-locations .home-filter-bar, .filter-container .filter-bar {
    background: #00c0ef;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    margin-top: -118px;
    border-radius: 36px;
    padding: 0;

    form, .nav-link {
      line-height: 36px;
    }

    form div {
      display: block;
      padding: 0 15px;
    }

    .nav-link {
      display: block;
      padding: 0 30px 0 30px;
    }

    .navbar-nav {
      line-height: 30px;
      padding: 0 30px 10px 30px;
    }

    .navbar-toggler {
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: #FFFFFF;
      line-height: 56px;
      margin: 0;
      text-align: left;
      padding: 0 60px 0 30px;
      width: 100%;
    }

    .navbar-toggler:focus {
      box-shadow: none;
    }
  }
  .home-locations .home-filter-bar .navbar-nav {
    padding: 0;
  }
  .filter-container .filter-bar {
    background: #00c0ef;
    margin-top: 0;
    padding: 0;
  }
  .home-events {
    padding-top: 30px;
  }
  .event-row {
    display: block;

    .event-content {
      padding: 5px 0 0 5px;
    }
  }
  .home-partners {
    line-height: initial;
    height: 197px;

    .spacer {
      display: none;
    }

    .home-header {
      display: block;
      margin: 0 auto 20px auto;

      h2 {
        padding: 0 20px;
        background: #F4F4F4;
        font-size: 28px;
      }
    }
  }
  .home-partners-list {
    overflow: auto;
    text-align: center;
  }
  footer .container .right {
    display: none;
  }
  footer {
    background: none;
    height: auto;
    margin-bottom: 0;

    .container {
      padding: 0;
      text-align: center;

      .left {
        display: block;
        background: #00c0ef;
        line-height: 40px;
        padding: 20px 10px 20px 10px;
      }

      ul {
        display: block;
      }

      li.first {
        border-left: none;
      }

      > div {
        padding-top: 160px;
        background-size: 85% auto;
        background-position: center top 15px;
      }
    }
  }
  .halozat {
    background: none;

    .content-border {
      padding-left: 0;
      background: none;
    }
  }

  blockquote {
    margin-left: 2rem;
    padding-left: 2rem;
  }
  .welcome {
    margin-bottom: 60px;

    .welcome-stock {
      height: 220px;
      background-size: 220px;
    }

    .welcome-text {
      hr {
        width: 100%;
      }
    }
  }
  .home-locations {
    padding: 90px 0 40px 0;
  }
  .home-events {
    padding-top: 20px;
  }
  .home-header {
    margin: 0 auto 20px auto;

    h2 {
      font-size: 28px;
    }
  }
  .event-list-image-container {
    max-width: 100%;
    margin-bottom: 35px;

    img {
      max-width: 100%;
    }

    .event-date {
      right: initial;
      top: initial;
      left: 20px;
      bottom: -24px;
    }
  }
  .article-image-container {
    margin-bottom: 35px;

    .article-image {
      margin-bottom: 0;
    }

    .item-img-cropped {
      margin-bottom: 0;
    }

    .event-date {
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      min-width: 74px;
      right: initial;
      top: initial;
      left: 20px;
      bottom: -24px;

      hr {
        width: 20px;
        border: none;
        outline: none;
        border-bottom: 1px solid #fff;
        margin: 6px auto 10px auto;
        color: #fff;
      }

      .month {
        font-size: 7px;
        line-height: 9px;
      }

      .day, .day.one {
        font-size: 22px;
        line-height: 14px;
        margin-bottom: 5px;
      }

      .date {
        background: #cccccc;
        border-radius: 30px 0;

        .date-content {
          padding: 12px 3px 10px 3px;
        }
      }

      .time {
        background: #00c0ef;
        border-radius: 20px 0 20px;

        font-size: 9px;
        line-height: 26px;
      }
    }
  }
  #searchModal form button.submit {
    right: 25px;
  }
  .pagination {
    line-height: 32px;

    .page a, .previous a, .first a, .next a, .last a, .current {
      margin: 0 3px;
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.event_maps_container {
  position: relative;
  height: 800px;

}

.maps_list_btn_container {
  position: relative;
  bottom: 100px;
  text-align: center;
  margin: 0 15%;
}

.event_maps {
  height: 730px;
  width: 100%;

  h2 {
    font-size: 22px;
    line-height: 27px;
  }

  .gm-style {
    .gm-style-iw-c {
      border-radius: 0 0 0 60px !important;
      max-width: 250px !important;
      overflow: initial;
      padding: 20px !important;
    }

    .gm-style-iw-tc {
      display: none;
    }

    .gm-style-iw-d {
      overflow: initial !important;
    }
  }

  .maps_marker {
    position: relative;

    .btn-gold {
      position: absolute;
      bottom: -40px;
      right: 0;
    }
  }

  .event-content {
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 20px;
  }

  .event-list-image-container {
    margin-bottom: 34px;

    .event-date {
      right: initial;
      top: initial;
      bottom: -26px;
      left: 20px;
    }
  }
}

.img-left-right-text {
  margin-bottom: 15px;
  text-align: justify;
  text-justify: inter-word;
}

.img-left-right-text img {
  float: left;
  padding-left: 0;
  padding-right: 1rem;
  max-width: 50%;
}
form#teamRegistration {
  color: $black;
  label {
    @include font($grey, Rubik, 400, 14px, normal);
  }
  .form-check-label {
    @include font($black, Rubik, 400, 18px, normal);
    a {
      color: $orange !important;
      font-size: 18px;
    }
  }
  .col-md-6 {
    padding: 10px;
  }
  .fade:not(.show) {
    opacity: 0;
    display: none;
  }

  .fade {
    transition: opacity 0.15s linear;
  }
  fieldset.mb-3 {
    margin-bottom: 0 !important;
  }
  .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
  }
  .tab-pane {
    padding: 10px;
  }
  .tab-pane.active {
    background-color: white;
  }
  .form-control, .form-select {
    background-color: $amber !important;
    border-radius: 16px;
    color: $black;
    border: 0;
    padding: 8px 16px;
    input {
      background-color: $amber !important;
    }
  }
    /*input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: $orange !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  .form-control:hover {
    background-color: $orange !important;
  }
  .form-control.error {
    background-color: $error-red !important;
    color: white;
  }
  button.nav-link {
    background-color: $light-grey;
    border-radius: 0;
    color: $grey !important;
    @include font(white, Rubik, 400, 18px, normal);

  }
  button.nav-link:hover {
    background-color: $grey;
    color: $black !important;
  }
  button.nav-link.active {
    background-color: $orange;
    color: white !important;
  }
  button.nav-link.active:hover {
    background-color: $orange;
  }
  span.error {
    margin-top: 5px;
    color: $black;
  }
  .form-group.row .col-sm-2 {
    width: 0 !important;
  }
  div.mb-3 {
    padding-left: 15px;
    margin-top: 15px;
  }
  button[type=submit]:hover{
    background-color: $mcc-dark-blue;
  }
}
.block-title {
  text-align: center;
  margin-bottom: 20px;
}
.group.opinion {
  padding: 20px;
  div.row {
    background-color: $light-grey;
    border-radius: 16px;
    padding: 20px 30px;
    margin: 0 auto 15px auto;
    h5 { @include font($black, Inter, 700, 24px, normal); }
  }
}
.group.news {
  img {
    border-radius: 16px;
    max-width: 100%;
    height: auto !important;
  }
}
